


































import { GetUser, PutUpdateUser } from "@/api/user";
import { GetResponseErrors } from "@/common/utils";
import TheSnackbar from "@/components/TheSnackbar.vue";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
export default Vue.extend({
  metaInfo: {
    title: 'Actualizar perfil',
  },
  components: { TheSnackbar },
  computed: {
    ...mapGetters("auth", ["user", "token"]),
  },
  data() {
    return {
      form: {
        id: 0,
        firstname: "",
        lastname: "",
        email: "",
        consorcio_id: 0,
        id_number: "",
      },
      isSubmitting: false,
    };
  },
  mounted() {
    this.form = {
      id: this.user.id,
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      email: this.user.email,
      consorcio_id: this.user.consorcio_id,
      id_number: this.user.id_number,
    };
  },
  methods: {
    ...mapMutations("auth", ["set"]),
    async handleSubmit() {
      this.isSubmitting = true;
      try {
        const res = await PutUpdateUser(this.form);
        const userRes = await GetUser();
        const user = userRes.data;
        this.set({
          user,
          token: this.token,
        });
        (this.$refs.snackbar as any).show("Usuario actualizado exitosamente");
      } catch (err) {
        (this.$refs.snackbar as any).show(GetResponseErrors(err));
      }
      this.isSubmitting = false;
    },
  },
});
